import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BannerInterior from '../../components/BannerInterior'
import BannerAgenda from '../../components/BannerAgenda'
import CateringItem from '../../components/CateringItem'
import poster from '../../images/banner-entradas.jpg'
import pic1 from '../../images/catering/entradas/rollo-vietnamita-1.jpg'
import pic2 from '../../images/catering/entradas/burrito-con-pollo-adobado-1.jpg'
import pic3 from '../../images/catering/entradas/rollo-primavera-1.jpg'
import pic5 from '../../images/catering/entradas/2.jpg'
import pic7 from '../../images/catering/entradas/4.jpg'
import pic10 from '../../images/catering/entradas/7.jpg'
import pic11 from '../../images/catering/plato-fuerte/1.jpg'
import pic12 from '../../images/catering/plato-fuerte/hamburgesa-de-sirlon-1.jpg'

const platillos = [
	{
		img: pic1,
		titulo: 'Rollo vietnamita',
		descripcion: ''
	},
	{
		img: pic2,
		titulo: 'Burrito con pollo adobado',
		descripcion: ''
	},
	{
		img: pic3,
		titulo: 'Rollo primavera',
		descripcion: ''
	},
	{
		img: pic5,
		titulo: 'Ensalada caprese',
		descripcion: ''
	},
	{
		img: pic7,
		titulo: 'Paté de Queso con Nuez',
		descripcion: ''
	},
	{
		img: pic10,
		titulo: 'Pan Artesanal',
		descripcion: ''
	},
	{
		img: pic11,
		titulo: 'Tartar de Atún',
		descripcion: ''
	},
	{
		img: pic12,
		titulo: 'Hamburguesa de sirlon',
		descripcion: ''
	},
]

const Catering = () => (
	<Layout>

		<SEO title="Entradas" />

		<BannerInterior
			small="Barcelona Catering"
			title="Entradas"
			caption=""
			imgsrc={poster}
			textstyle="darks"
		/>

		<section className="platillos-blocks">
			<div className="container">
				<div className="row">

					{platillos.map((platillo, index) => (
						<div className="col-12 col-sm-6 col-md-4" key={index}>
							<CateringItem
								img={platillo.img}
								titulo={platillo.titulo}
								descripcion={platillo.descripcion}
								categoria="Entrada"
							/>
						</div>
					))}

				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-7"/>

	</Layout>
)

export default Catering
