import React, {Component} from "react"
import { SimpleImg } from 'react-simple-img'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import closeicon from '../images/i-close-modal.svg'

class CateringItem extends Component{

	constructor(props) {
		super(props);
		this.state = {
			modal: false,
		};

		this.toggle = this.toggle.bind(this);
	  }

	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal
		}));
	}

	render(){

		const closeBtn = <img className="close" alt="cerrar" onClick={this.toggle} src={closeicon}/>;

		return(
			<div className="item">

				<figure>
					<div className="pic" onClick={this.toggle}>
						<SimpleImg src={this.props.img} placeholder={false} alt={this.props.titulo}/>
						<svg aria-hidden="true" width="52" height="52" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M26 0c14.36 0 26 11.64 26 26S40.36 52 26 52 0 40.36 0 26 11.64 0 26 0z" fill="#FFF" fillRule="nonzero"/><g stroke="#B16533" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M36.395 24.814H15.802M27 35.594V15"/></g></g></svg>
					</div>
					<figcaption>
						<h2 onClick={this.toggle}>{this.props.titulo}</h2>
						<p>{this.props.descripcion}</p>
					</figcaption>
				</figure>

				<Modal size="xl" centered isOpen={this.state.modal} toggle={this.toggle} className="catering-modal">
					<ModalHeader toggle={this.toggle} close={closeBtn}>
						<div className="heading">
							<small>{this.props.categoria}</small>
							<h2>{this.props.titulo}</h2>
							<p>{this.props.descripcion}</p>
						</div>
					</ModalHeader>
					<ModalBody>
						<SimpleImg src={this.props.img} placeholder={false} alt="Agenda tu cita"/>
					</ModalBody>
				</Modal>

			</div>
		)
	}
}

export default CateringItem
